import React from "react"
import { Layout, SEO } from "../components"
import PrivacyPolicyMain from "../components/privacy-policy/privacy-main"

const PrivacyPolicy = () => {
  return (
    <Layout>
      <SEO title="Privacy Policy" />
      <PrivacyPolicyMain />
    </Layout>
  )
}

export default PrivacyPolicy
